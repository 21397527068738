<template>
<div class="page">
  <nav-bar :id="1"></nav-bar>
  <div class="search">
    <input v-model="searchValue" placeholder="请输入查找的应用" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
    <div class="search-divider"></div>
    <img class="search-icon" :src="require('@/assets/img/home/search-result.png')" />
    <van-icon v-if="searchValue" class="clear-btn" @click="onClear" name="clear" color="#ccc" size="16" />
    <div class="search-btn" @click="onSearch">搜索</div>
  </div>
  <div class="func" v-for="menu in menuList" :key="menu.menuId">
    <div>
      <van-row>
        <van-col span="8">
          <div style="padding-left:20px;margin:10px 0;font-size:16px;position: relative;">
            {{menu.name}}
          </div>
        </van-col>
      </van-row>
      <van-row>
        <van-grid :border="false" :column-num="5">
          <van-grid-item v-for="(item, index) in menu.list" :key="index" @click="functionClick(item.htmlUrl, item.name,item.pageUrl,item.appCode,item.appId)" :icon="getIcon(item.icon)" :text="item.name"></van-grid-item>
        </van-grid>
      </van-row>
    </div>
  </div>
  <div v-if="menuList.length == 0" class="none">暂无该应用</div>
  <pwd-verify ref="pwdVerify" @close="onClose" v-if="controledVerifyPwd"></pwd-verify>
</div>
</template>

<script>
import navBar from "@/components/navBar/navBar";
import {getImageStream} from '@/utils'
import pwdVerify from '@/components/pwdVerify/pwdVerify'

export default {
name: "work",
  components: {
    navBar,
    pwdVerify
  },
  data () {
    return {
      controledVerifyPwd: false,
      pwdUrl: '',
      menuList: [],
      oldMenuList: [],
      searchValue: ''
    }
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getBackToken()
  },
  methods: {
    getBackToken() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/appauth'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$globalData.backToken = data.token
          this.getDataList()
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
    getIcon (path) {
      return getImageStream(path,process.env.VUE_APP_BASE_BACK_URL);
    },
    getDataList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/nav'),
        method: 'get',
        params: this.$http.adornParams({
          clientType: this.$depth == 4 ? 4 : 2
        })
      }).then(({data}) => {
        this.$toast.clear()
        let menuList = data.menuList
        // this.menuList = data.menuList
        // this.oldMenuList = JSON.parse(JSON.stringify(data.menuList))
        this.$httpBack({
          url: this.$httpBack.adornUrl('/app/application/info/myApp'),
          method: 'get',
          params: this.$httpBack.adornParams({
            page: 1,
            limit: -1,
            belongs: 2,
            temporaryUse: 1, // 临时使用，上线需要去除该参数
          })
        }).then(({ data }) => {
          if (data && data.code == 0) {
            let allMenuList = []
            menuList.map(item => {
              if (item.list && item.list.length > 0) {
                item.list.map(it => {
                  allMenuList.push(it)
                })
              }
            })
            allMenuList.map(item => {
              if (item.code && item.code.indexOf('&') != -1) {
                item.code = item.code.replace('wggg&', '')
              }
            })
            let tepArrA = []
            let tepArrB = []
            data.page.list = data.page.list.filter(item => {return item.pageUrlH5 && item.pageUrlH5 != '/'})
            data.page.list.map(item => {
              if (item.appCode == 'sjgl_xcjl') {
                tepArrA.push(item)
              } else if (item.appCode == 'sjgl_wtjl') {
                tepArrA.push(item)
              } else if (item.appCode == ' sjgl_sgzf_zfjl') {
                tepArrA.push(item)
              } else if (item.appCode == 'sjgl_bxjl') {
                tepArrA.push(item)
              } else if (item.appCode == 'yy_bszn') {
                tepArrA.push(item)
              } else if (item.appCode == 'sqgg') {
                tepArrA.push(item)
              } else if (item.appCode == 'sjgl_hdjl') {
                tepArrA.push(item)
              } else if (item.appCode == 'wjda') {
                tepArrA.push(item)
              } else {
                tepArrB.push(item)
              }
            })
            tepArrA = tepArrA.filter(item => {
              return allMenuList.every(it => {
                return item.appCode != it.code
              })
            })
            let list = tepArrB.concat(tepArrA).map(val => {
              return {
                ...val,
                // htmlUrl: val.pageUrlH5.indexOf('/') == 0 ? val.pageUrlH5 :
                //     val.pageUrlH5.indexOf('http') == 0 ? '/appSSO?url=' + encodeURIComponent(val.pageUrlH5) +
                //         '&appCode=' + val.appCode + '&appId=' + val.appId + '&appName=' + val.appName : '',
                htmlUrl: val.pageUrlH5.indexOf('/') == 0 ? val.pageUrlH5 :
                    val.pageUrlH5.indexOf('http') == 0 ? '/appSSO' : '',
                pageUrl: val.pageUrlH5,
                appCode: val.appCode || '',
                appId: val.appId  || '',
                icon: val.appIcon,
                name: val.appName
              }
            })
            if (menuList.some(item => item.code == 'yy')) {
              menuList.map(item => {
                if (item.code == 'yy') {
                  item.list = item.list.concat(list)
                }
              })
            } else {
              menuList.push({
                code: 'yy',
                name: '应用',
                list
              })
            }
            this.menuList = menuList
            this.oldMenuList = JSON.parse(JSON.stringify(this.menuList))
          }
        } ,err => { this.$toast.clear() })
      })
    },
    onSearch () {
      let value = this.searchValue.trim()
      if (value != '') {
        let list = JSON.parse(JSON.stringify(this.oldMenuList))
        list.forEach(item => {
          item.list = item.list.filter(val => val.name.indexOf(value) != -1)
        })
        list = list.filter(item => item.list.length != 0)
        this.menuList = list
      } else {
        this.menuList = JSON.parse(JSON.stringify(this.oldMenuList))
      }
    },
    onClear () {
      this.menuList = this.oldMenuList
      this.searchValue = ''
    },
    functionClick (url, name,pageUrl,appCode,appId) {
      if (appCode == 'county_form' || appCode == 'uht_county_form') {
        this.getCountyFormCode(pageUrl, name, appId, appCode)
      }
      if (url.indexOf('http') == 0) {
        // window.location.replace(url)
        this.$router.push(url)
      }else if(url == '/appSSO' && appCode !== 'county_form' && appCode !== 'uht_county_form') {
        this.$router.push({path: url, query: {url: pageUrl,appCode,appId,appName: name}})
      } else if (appCode !== 'county_form' && appCode !== 'uht_county_form'){
        if (name == '居民管理' || name == '房屋管理' || name == '出租屋') {
          this.$http({
            url: this.$http.adornUrl('/wxapp/secondVerifyFirst'),
            method: 'post'
          }).then(({data}) => {
            if (data.msg == '需要验证') {
              this.controledVerifyPwd = true
              this.$nextTick(() => {
                this.$refs.pwdVerify.visible = true
              })
              this.pwdUrl = url
            } else {
              this.$router.push(url)
            }
          })
        } else {
          this.$router.push(url)
        }
      }
    },
    onClose (msg) {
      if (msg) {
        this.$router.push(this.pwdUrl)
      }else{
        this.$toast.fail('密码不正确,无法查看该模块信息')
      }
    },
    clickFn (url) {
      this.$router.push(url)
    },
    getCountyFormCode (url, name, appId, code) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/user/getCountyFormCode'),
        method: 'post'
      }).then(({data}) => {
        if (data) {
          let countyFormCode = data.code
          if (data.code){
            this.$router.push({path: '/appSSO', query: {url, appId, appCode:code, appName:name, countyFormCode}})
          }

        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.page {
  padding-bottom: 166px;
}
.search {
  margin-top: 12px;
  margin-bottom: 36px;
  position: relative;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  width: 690px;
  height: 72px;
  background: #fff;
  border-radius: 20px;
}
.search-content {
  padding-top: 8px;
  font-size: 24px;
  color: #666;
  padding-left: 100px !important;
  display: block !important;
  width: 500px !important;
  background-color: transparent;
  border: none;
  line-height: 60px;
}
.search-content::placeholder {
  color: #ccc;
}
.clear-btn {
  position: absolute;
  top: 18px;
  right: 135px;
}
.search-divider {
  width: 2px;
  height: 40px;
  background: #DBDBDB;
  border-radius: 2px;
  position: absolute;
  left: 80px;
  top: 16px;
}
.search-icon {
  position: absolute;
  top: 20px;
  left: 24px;
  width: 32px;
  height: 32px;
}
.search-btn {
  font-size: 24px;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 112px;
  height: 64px;
  line-height: 64px;
  background-color: #4581F8;
  color: #fff;
}


.none {
  margin-top: 40px;
  width: 100%;
  font-size: 36px;
  text-align: center;
  color: #ccc;
}
.func {
  margin: 24px 30px;
  background-color: #fff;
  border-radius: 20px;
  padding: 24px 0;
}
::v-deep .van-grid-item__content {
  padding: 10px 0;
}
::v-deep .van-icon__image {
  width: 72px !important;
  height: 72px !important;
}
::v-deep .van-grid-item__text {
  margin-top: 0 !important;
  font-size: 24px !important;
  width: 136px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.func-warning {
  padding-left: 44px;
  margin: 32px 20px;
  height: 88px;
  line-height: 88px;
  background-color: #F5F8FF;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
</style>
